import React, { useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Button from 'components/button';

const questions = [
  {
    title: 'Who are Maistro?',
    answer:
      "When it comes to buying services, Maistro is a brand new model. A smart system that makes it easy and fast for our clients to buy better. With more control and visibility.We’re a procurement software service provider harnessing cutting edge technology and data analytics to lead the way in tail spend management.A key component to this vision is having access to great suppliers, this is where you come in. At Maistro we pride ourselves in surfacing new innovative suppliers and championing fresh talent that's relevant to our clients needs.",
  },
  {
    title: 'What is the onboarding process?',
    answer:
      'It’s an invite only platform to guarantee high vetting principles and quality to both buyer and supplier. A member of the Maistro team will review your submission to join and contact you if we think you’re a good fit for the platform. We will take some details over the phone and send out an invite to the platform where you can activate you accounts – that’s it! You will then appear in matching results for tenders. We do ask that you complete our vetted information to ensure we have the details we need from you to pass our vetted checks. This is crucial to ensure you’re getting sent the very best opportunities to match your services.',
  },
  {
    title: 'What is the tendering process?',
    answer:
      'If you appear in the matching results for our buyers and they invite you to tender, an email notification will be sent to you asking for you to submit a tender for the work. To respond to the tender, log into the platform to see the tender project brief and follow the steps. Once you’re happy with your response you can submit. You will be notified if you have been successful in your submission via email and from the platform dashboard.',
  },
  {
    title: 'What happens if I have a problem?',
    answer:
      'Our supplier relationship team are always on hand to help support you with your tendering submissions and any platform questions. You can also download our useful guide whilst logged into the platform.',
  },
  {
    title: 'Are there any costs involved?',
    answer:
      'Our platform is complete free to suppliers, by signing up you are in with the chance to be matched with some amazing tender opportunities from our ever growing customer data base?',
  },
  {
    title: 'Who are your customers/buyers?',
    answer:
      'We have recently made some significant updates to our platform, along with attracting a number of new and well-known brands looking for services in sectors such as Business and Professional Services, Facilities Management, IT and Marketing to name a few.',
  },
  {
    title: 'What happens to my data?',
    answer:
      'We only ask for your data once, this is to set you up on our system. Please see our Privacy Policy for more details.All data collected by Maistro is transmitted over secure connections to Azure SQL database located behind access controlled firewalls and is encrypted at rest. Data is then backed up to secure geo-redundant storage within the Azure environment.',
  },
  {
    title: 'How many other suppliers will I be up against?',
    answer:
      'All tenders or requests for quote are now invite only. This will limit the number of other vendors being asked to respond. Because we are frontloading the task of getting all supplier information, certification and skillsets, those running the tender will already have a good idea about the set of suppliers they are seeking, rather than casting a wide net to unknown suppliers.',
  },
];

const Question = ({ question, answer }) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`,
    );
  }

  return (
    <div>
      <button
        type="button"
        className="py-6 appearance-none focus:outline-none border-b border-secondary w-full text-left"
        onClick={toggleAccordion}
      >
        <h4 className="uppercase flex">
          <span className="w-8 text-primary block ">+</span>
          <span className="flex-1">{question}</span>
        </h4>
      </button>
      <div
        className="overflow-hidden duration-200"
        style={{ maxHeight: `${setHeight}`, transition: '0.2s all' }}
        ref={content}
      >
        <div className="bg-secondary text-black p-10">{answer}</div>
      </div>
    </div>
  );
};

const Box = ({ title, description, icon }) => (
  <div className="text-center mb-12">
    <div className="w-32 my-0 mx-auto mb-12">
      <img src={icon} alt={title} className="w-full h-full" />
    </div>
    <div className="mb-4">
      <h3 className="text-primary normal-case font-semibold">{title}</h3>
    </div>
    <p className="text-primary font-normal">{description}</p>
  </div>
);

const InputElem = ({ formik, id, title }) => (
  <div className="w-full">
    <p>{title}</p>
    <input
      name={id}
      className="mt-1 pl-3 border-2 border-gray200 bg-gray50 w-full h-10 outline-none rounded-md"
      {...formik.getFieldProps(id)}
    />
    {formik.touched[id] && formik.errors[id] ? (
      <div className="text-sm text-red600 mt-1">{formik.errors[id]}</div>
    ) : null}
  </div>
);

const Supplier = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const submitToHubSpot = async (values) => {
    await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTALID}/${process.env.GATSBY_HUBSPOT_FORM_GUID_ID}`,
      {
        portalId: process.env.GATSBY_HUBSPOT_PORTALID,
        formGuid: process.env.GATSBY_HUBSPOT_FORM_GUID_ID,
        fields: [
          {
            name: 'firstname',
            value: values.firstName,
          },
          {
            name: 'lastname',
            value: values.lastName,
          },
          {
            name: 'email',
            value: values.email,
          },
          {
            name: 'message',
            value: values.message,
          },
          {
            name: 'company',
            value: values.companyName,
          },
          {
            name: 'blog_maistro_blog_28047747458_subscription',
            value: values.emailSubscription ? 'monthly' : '',
          },
          {
            name: 'phone',
            value: values.phoneNumber,
          },
          {
            name: 'linkedinbio',
            value: values.linkedinBio,
          },
          {
            name: 'numberofemployees',
            value: values.numberOfEmployees,
          },
          {
            name: 'website',
            value: values.websiteUrl,
          },
          {
            name: 'country',
            value: values.countryRegion,
          },
        ],
      },
    );
    setIsSubmitted(true);
  };

  return (
    <Layout>
      <SEO
        title="Suppliers"
        description="Become a registered supplier on the Maistro marketplace"
      />
      <div>
        <div className="grid grid-rows-2 md:grid-cols-2 md:grid-rows-none">
          <div className="bg-primary flex justify-center items-center py-6 lg:py-10">
            <div className="w-10/12">
              <h1 className="text-white normal-case font-medium">
                Championing new Supplier Talent
              </h1>
              <h4 className="text-white normal-case text-2xl my-9">
                Free for service providers, our procurement platform makes
                selling services simpler, faster and better for companies like
                yours, ultimately saving you an abundance of time and an
                opportunity to win new business.
              </h4>
              <a href="/files/supplier_guide.pdf" target="_blank">
                <Button
                  text="Download Supplier Guide"
                  bg="primary"
                  rightIcon="/images/chevronRightIcon.svg"
                />
              </a>
            </div>
          </div>
          <div
            style={{
              backgroundImage: 'url(/images/christin-hume-unsplash.jpg)',
            }}
            className="bg-cover bg-center"
          />
        </div>
      </div>

      <div className="bg-stone py-12">
        <div className="container">
          <div className="text-center space-y-4">
            <h1 className="font-medium ">Why Join Maistro?</h1>
            <p className="text-primary">
              An invite only platform to guarantee high vetting principles and
              quality to both buyer and supplier.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-12 sm:gap-x-12">
            <Box
              title="Quality Supply Chain"
              description="Join a selective pool of service providers on our vetted supply chain."
              icon="/images/supply_chain.png"
            />
            <Box
              title="Open and Fair Process"
              description="A level playing field - compete on merit for your capability and performance."
              icon="/images/open_fair.png"
            />
            <Box
              title="Matching Algorithm"
              description="Receive tender opportunities that match your expertise and knowledge."
              icon="/images/matching_algorithim.png"
            />
            <Box
              title="Onboarding"
              description="Our onboarding process is quick and simple, guiding you through every step."
              icon="/images/onboarding.png"
            />
            <Box
              title="Tender Opportunities"
              description="Tender to small and medium-sized businesses including well-known brands."
              icon="/images/tender_opportunities.png"
            />
            <Box
              title="Ongoing Support"
              description="Get expert assistance from our supplier relationship team."
              icon="/images/ongoing_support.png"
            />
          </div>
        </div>
      </div>

      <div className="bg-white py-12">
        <div className="container">
          <div className="grid grid-cols-1 gap-12 text-center">
            <h1 className="text-primary font-semibold">
              Sell your services and respond to new business opportunities
            </h1>
            <p className="text-primary">
              If you would like to find out more about being part of the Maistro
              pool of suppliers. Simply fill out this form and we&prime;ll get
              back in touch.
            </p>
            <a href="mailto:support@maistro.com?subject=Supplier Query">
              <Button
                text="Email Us"
                bg="primary"
                rightIcon="/images/chevronRightIcon.svg"
              />
            </a>
            <div className="flex flex-col sm:flex-row items-center sm:items-start rounded-2xl bg-secondary p-4 sm:p-8 gap-y-8 sm:gap-x-8">
              <div className="sm:w-1/4 w-36">
                <img
                  src="/images/jr-profile.jpg"
                  alt="jr-profile"
                  className="w-full rounded-full border-4 border-solid border-white"
                />
              </div>
              <div className="sm:w-3/4 w-full text-primary">
                <p>
                  &ldquo;I&rsquo;ve enjoyed working with Maistro, who invited me
                  to tender for a year-long contract with a global client, which
                  I successful won. Their step-by-step online tendering process
                  was clear and straightforward, with human help on hand
                  whenever I needed assistance. Many thanks Maistro, I look
                  forward to deepening our working partnership.&rdquo;
                </p>
                <div className="flex flex-col font-semibold mt-5">
                  <span>Arif Mohamed, Freelance Writer,</span>
                  <span>Editor & Media Consultant</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-stone">
        <div className="container py-20">
          <h1 className="text-black mb-4 normal-case">FAQs</h1>
          {questions.map((q) => (
            <Question key={q.title} question={q.title} answer={q.answer} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Supplier;
